<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="filteredAccounts"
      :search="search"
      dense
      height="75vh"
      :loading="loadingTable"
      fixed-header
      :footer-props="{
        'items-per-page-options':[10, 20, 30, 40]
      }"
      @click:row="rowClick"
        >
      <template v-slot:top>
        <v-toolbar
          dark
          color="primary"
          flat
        >
          <v-toolbar-title>Utenti</v-toolbar-title>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="fa-search"
            label="Cerca"
            single-line
            hide-details
            outlined
          />  
        </v-toolbar>
         <v-btn text>
          <v-btn
            @click="exportToExcel"
            :loading="loadingExport"
            :disabled="loadingTable"
            text
          >
            Esporta Excel
          </v-btn>
        </v-btn>
      </template>
    
    </v-data-table>
      
  </v-card>
</template>
<script>
import {
  getAllGrid,
  getAllExcel
  } from "@/api/accounts.api";
import { mapGetters } from "vuex";
import * as XLSX from 'xlsx';
export default {
  data () {
    return {
      search: "",
      headers: [
       {text: "Id", value: "id", type: "string"},
        {text: "Email", value: "email", type: "string"},
        { text: "Telefono", value: "phone", type: "string", filterActive: false },
        { text: "Rag Sociale", value: "businessName", type: "string", filterActive: false },
        { text: "P.IVA", value: "vat", type: "string", filterActive: false },
        { text: "CF Azienda", value: "ssnCompany", type: "string", filterActive: false },
        { text: "Sponsor", value: "sponsor", type: "string", filterActive: false },
        
      ],
      accounts: [],
      userCredit:{},
      loading: false,
      loadingExport: false,
      filterModal: {
        visible: false,
        selectedFilter: {},
        value: ""
      },
      filters: [],
      loadingTable: false  
    };
  },
  mounted () {
    this.loadDataSource();
    
  },
  computed: {
    ...mapGetters({ user: "authentication/user" }),
 
    
    filteredAccounts () {
      let accounts = this.accounts
      this.filters.forEach(filter => {
        if (typeof filter.value == "boolean") {
          accounts = accounts.filter(c => c[filter.field] === filter.value)
        }
        else { accounts = accounts.filter(c => {

          if(typeof c[filter.field] != 'undefined'){
            if(c[filter.field].includes(filter.value)){
              return c;
            }
          }

        }) }
      })
      return accounts
    }
  },
  methods: {
    loadDataSource () {
      this.loadingTable = true
      getAllGrid().then((result) => {
         this.$nextTick(()=>{
           this.accounts = result.data;
         })
        this.loadingTable = false
      }).catch(err=>{
        console.log(err);
      });
    },
    exportToExcel () {
        this.loadingExport=true
        getAllExcel().then(res=>{
        let wb = XLSX.utils.book_new()
        var mappedData = res.data.map(row => {
          let obj= {
            "Numero Utente": row.id,
            "Email": row.email,
            "Nome": row.firstName,
            "Cognome": row.lastName,
            "Forma Giuridica": row.legalForm,
            "Rag.Soc": row.businessName,
            "P.IVA": row.vat,
            "Telefono": row.phone,
            "Via": row.street,
            "Citta'": row.city,
            "CAP": row.zip,
            "Provincia": row.province,
            "Pec": row.pec,
            "Sponsor": row.sponsor,
            "Email verificata": row.isVerified,
            "Contratto verificato":row.contractVerified,
            "Nome operatore referente": row.attorneyName,
            "Telefono operatore referente": row.attorneyPhones,
            "Numero documento identità": row.attorneyIDCardNumber,
            "Data scadenza documento identità": row.attorneyIDCardExpiryDate,
            "Rilasciato da": row.attorneyIDCardIssuingAuthority,
            "Data Registrazione": row.created,
            "Data ultima modifica": row.updated,
            "Pratiche in bozza ECOBONUS": row.numModulesDraftEcobonus,
            "Pratiche inviate ECOBONUS": row.numModulesSentEcobonus,
            "Pratiche in bozza BONUS CASA": row.numModulesDraftBonusCasa,
            "Pratiche inviate BONUS CASA": row.numModulesSentBonusCasa,
            "Pratiche in bozza BONUS SICUREZZA": row.numModulesDraftBonusSicurezza,
            "Pratiche inviate BONUS SICUREZZA": row.numModulesSentBonusSicurezza
      
            }
          return obj
        })
      
        let ws = XLSX.utils.json_to_sheet(mappedData);
        XLSX.utils.book_append_sheet(wb, ws, 'Utenti')
        XLSX.writeFile(wb, `Estrazione-utenti-${this.dateToYMD()}.xlsx`)
        this.loadingExport=false
      })
      },
    dateToYMD (date) {
      const dateObj = date ? new Date(date) : new Date();
      var d = dateObj.getDate();
      var m = dateObj.getMonth() + 1; //Month from 0 to 11
      var y = dateObj.getFullYear();
      return '' + (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;
    },
    rowClick (element) {
      this.$router.push("/administration/users/"+element.id).catch(err=>{})
    },
  },
};
</script>
<style >
.text-start {
  font-size: 0.8rem !important;
}
</style>